.custom-toolbar-dropdown {
  display: flex;
  justify-content: end;
}

.custom-toolbar-dropdown .MuiSelect-outlined.MuiSelect-outlined {
  padding: 10px 33px 10px 16px;
}

.topic-table table tbody tr td:first-child {
  background-color: white !important;
}

.topic-table table tbody tr td:first-child,
.topic-table table tbody tr td:first-child {
  width: 100px !important;
}

/* .custom-select label.MuiFormControlLabel-root {
  justify-content: center;
} */
@media (max-width: 600px) {
  .MuiFormGroup-root {
    width: 100%;
  }
}
