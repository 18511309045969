.col {
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 7.5px;
  padding-right: 7.5px;
  position: relative;
}
.col-90 {
  flex: 0 0 90%;
  max-width: 90%;
  padding-left: 7.5px;
  padding-right: 7.5px;
  position: relative;
}
.col-10 {
  flex: 0 0 10%;
  max-width: 10%;
  padding-left: 7.5px;
  padding-right: 7.5px;
  position: relative;
}

.row {
  display: flex;
  margin-left: -7.5px;
  margin-right: -7.5px;
}
.card-header {
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.75rem 0;
  position: relative;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card-title {
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0;
}
.card-input-label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.form-control-input {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: inset 0 0 0 transparent;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-danger {
  color: #fff !important;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
  box-shadow: none !important;
}

.detail-card {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
