header {
  background-color: #fff !important;
}
header button svg {
  color: rgba(0, 0, 0, 0.7) !important;
}
header h6 {
  color: rgba(0, 0, 0, 0.7) !important;
}
#sidebar-drawer .MuiPaper-root {
  background-color: #343a40 !important;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22) !important;
  padding: 0px 8px;
  color: #c2c7d0;
}
#sidebar-drawer ul a div,
#sidebar-drawer ul a div span {
  color: #fff !important;
}
/* #sidebar-drawer ul a div div{
 background-color: #fff !important;
} */
#sidebar-drawer ul a {
  background-color: transparent;
}
#sidebar-drawer ul div {
  padding-left: 0 !important;
}
#sidebar-drawer ul a:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #fff !important;
}
#sidebar-drawer ul a:focus div div,
#sidebar-drawer ul a:active div div {
  background-color: #fff !important;
}
#sidebar-drawer ul a:focus,
#sidebar-drawer ul a:active {
  background: #005ba3 !important;
  color: #fff !important;
  /* padding: 0.5rem 0rem; */
  padding-left: 16px;
  padding-right: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 0.25rem;
  background-color: none !important;
}

/* .makeStyles-linkIconActive,.makeStyles-linkTextActive {
    color: #fff !important;
} */

.small-box h5.MuiTypography-colorTextSecondary {
  color: #fff;
}
.small-box p.MuiTypography-root.MuiTypography-body1 {
  color: #fff;
}
.small-box-footer {
  color: #fff;
}
.small-box.bg-info .MuiPaper-root {
  background-color: #17a2b8 !important;
}
.small-box.bg-success .MuiPaper-root {
  background-color: #28a745 !important;
}
.small-box.bg-warning .MuiPaper-root {
  background-color: #ffc107 !important;
}
.small-box.bg-danger .MuiPaper-root {
  background-color: #dc3545 !important;
}
.small-box.bg-teal .MuiPaper-root {
  background-color: #065555 !important;
}
.small-box.bg-secondary .MuiPaper-root {
  background-color: #073a6e !important;
}
.footer {
  background: #fff;
  border-top: 1px solid #dee2e6;
  color: #869099;
  padding: 1rem;
  width: 100% !important;
}
.footer p {
  color: #869099;
}
#main-content {
  padding: 24px 0px;
}
.content-grid,
#main-content h1,
#main-content div a.addlink {
  padding: 0px 24px;
}
.newBtn {
  color: #fff !important;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
  box-shadow: none !important;
}
.editBtn {
  color: #17a2b8;
}
.deleteBtn {
  color: #dc3545 !important;
}
.viewBtn {
  color: #2c60a4;
}
#logo-container,
.loginBtn {
  background-color: #005ba3 !important;
}
#login-footer-txt {
  color: #005ba3;
}

/*ck-editor*/
.ck-editor__editable {
  min-height: 150px;
}
.ck-editor {
  width: 100% !important;
}
/**/
.organization-tab .MuiTabs-flexContainer button {
  border: 1px solid #ccc;
  background-color: #f1f1f1;
  margin-top: 20px;
  margin-bottom: 10px;
  min-width: 115px !important;
  opacity: 1;
  text-transform: none;
  letter-spacing: 0.8px;
  font-size: 15px;
  line-height: 25px;
}
.organization-tab .MuiTabs-flexContainer .Mui-selected {
  background-color: #2c60a4;
  color: #fff;
}
.organization-tab .MuiTabs-indicator {
  background: none !important;
  border-bottom: none !important;
  height: 0px !important;
}
.note-color {
  color: #2c60a4;
  font-weight: 600;
}

.content-grid {
  display: flex;
  justify-content: center;
}

.cards-page .content-grid {
  justify-content: start;
}

.MUIDataTableToolbar-titleRoot-84,
h6 {
  font-weight: 600 !important;
}

.ck-font-size-dropdown,
.ck-code-block-dropdown {
  display: none !important;
}

/* .form-padding {
  padding: 24px;
} */

/* @media (max-width: 600px) {
  .form-padding div,
  .form-padding {
    padding: 0px !important;
  }
} */

.ml-2 {
  margin-left: 10px;
}

.manage-dropdown {
  display: flex;
  align-items: center;
  gap: 10px;
}

.manage-dropdown .addlink {
  margin: 0;
}

.language-dropdown {
  display: flex;
  justify-content: flex-end;
}

.language-dropdown.forweb div#language {
  padding: 10px;
}
.language-dropdown.formobile {
  display: none;
}

.language-dropdown .main-dropdown {
  width: 200px;
}

.loader-table {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

@media only screen and (max-width: 600px) {
  .content-grid {
    display: block !important;
  }
  .language-dropdown .main-dropdown {
    width: 100%;
  }

  .language-dropdown.formobile {
    display: block;
    margin-bottom: 15px;
  }

  .language-dropdown.forweb {
    display: none;
  }
}
