.col {
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 7.5px;
  padding-right: 7.5px;
  position: relative;
}
.row {
  display: flex;
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.imagePreview {
  margin-top: 10px;
}

.imagePreview img {
  min-height: 150px;
  min-width: 200px;
}

.header-logos {
  margin-top: 20px;
  display: flex;
  gap: 20px;
}

.cat-sub-btn span {
  display: flex !important;
}

.helperText {
  color: red;
  font-size: small;
}
