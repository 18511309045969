.ck.ck-reset.ck-editor.ck-rounded-corners {
  width: 100% !important;
}

.window-class {
  display: block;
}

.mobile-class {
  display: none;
}

@media (max-width: 600px) {
  .window-class {
    display: none;
  }

  .mobile-class {
    display: block;
  }
}
