/* .footer {
  width: 100vw;
  z-index: 1201;
  transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  color: #fff;
  background-color: #536dfe;
  bottom: 0;
  left: auto;
  right: 0;
  position: fixed;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
} */

.title-editor .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused),
.title-editor .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.title-editor .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  border-color: var(--ck-color-base-border);
  min-height: 50px;
}

.title-editor {
  width: 100%;
}

.add-btn {
  height: 30px;
  width: 30px;
  background-color: green;
  color: white;
  font-size: large;
  /* border-radius: 50%; */
  border: none;
  transition: 0.1s;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.remove-btn:hover,
.add-btn:hover {
  scale: 1.1;
}

.remove-btn {
  height: 30px;
  width: 30px;
  background-color: rgb(255, 68, 68);
  color: white;
  font-size: large;
  /* border-radius: 50%; */
  border: none;
  transition: 0.1s;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fieldArray-set {
  background-color: #f6f7ff;
  padding: 20px;
}

/* .adjust-width {
  display: block;
  width: 500px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
} */

.fieldArrayBtns {
  display: flex;
  gap: 10px;
}

@media (max-width: 600px) {
  .fieldArray-set {
    padding: 10px;
  }
}

.screen-table table tr th:nth-child(1) {
  width: 100px !important;
  min-width: 100px !important;
}

.screen-table table tr th:nth-child(2) {
  width: 200px !important;
  min-width: 200px !important;
}

.screen-table table tr th:nth-child(3) {
  width: 40% !important;
  min-width: 40% !important;
}
