.col {
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 7.5px;
  padding-right: 7.5px;
  position: relative;
}
.col-90 {
  flex: 0 0 90%;
  max-width: 90%;
  padding-left: 7.5px;
  padding-right: 7.5px;
  position: relative;
}
.col-10 {
  flex: 0 0 10%;
  max-width: 10%;
  padding-left: 7.5px;
  padding-right: 7.5px;
  position: relative;
}

.row {
  display: flex;
  margin-left: -7.5px;
  margin-right: -7.5px;
}
.card-header {
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.75rem 0;
  position: relative;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card-title {
  font-size: 1.1rem;
  font-weight: 400;
  margin: 0;
}
.card-input-label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.form-control-input {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: inset 0 0 0 transparent;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  background-color: transparent;
}
.table-bordered {
  border: 1px solid #dee2e6;
}
.table-striped tbody tr:nth-of-type(odd) {
  /* background-color: rgba(0, 0, 0, 0.05); */
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  text-align: left;
  color: #212529;
}
.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  /* box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2); */
  margin-bottom: 1rem;
  /* box-shadow: 0px 2px 4px -8px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); */
  /* box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); */
}

.detail-card {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}
.assignBtn {
  color: #fff !important;
  background-color: #117a8b !important;
  border-color: #10707f !important;
  padding: 0.25rem 0.5rem !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  border-radius: 0.2rem !important;
  text-transform: none !important;
}
.btn-info {
  color: #fff !important;
  background-color: #005ba3 !important;
  border-color: #005ba3 !important;
  box-shadow: none !important;
  text-transform: none !important;
}
.btn-secondary {
  color: #fff !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
  box-shadow: none;
  text-transform: none !important;
}

.table-container
  .MuiPaper-root.MUIDataTable-paper-61.undefined.MuiPaper-elevation4.MuiPaper-rounded {
  padding-top: 10px;
  box-shadow: none;
  border-radius: 0px;
  border-top: 1px solid #dee2e6;
}

.bulkupload-dialog.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
  width: 400px;
  height: 260px;
}

.MuiDialog-container.MuiDialog-scrollPaper {
  height: 100vh;
  width: 100vw;
}

.addlink {
  padding: 0px !important;
  margin-left: 10px;
}

.toolbar-btn {
  font-size: smaller !important;
  margin-left: 5px !important;
  /* text-transform: none !important; */
}

.toolbar-btn .MuiButton-label {
  display: flex !important;
  align-items: center !important;
}

/* .toolbar-btn .MuiButton-label span {
  margin-top: 3px !important;
  margin-left: 5px !important;
} */

.export-container {
  height: 500px;
  width: 600px !important;
}

.upload-btn {
  border: none;
  background: none;
  padding: 12px;
  border-radius: 50%;
}

.upload-btn:hover {
  color: #536dfe;
  background-color: rgba(0, 0, 0, 0.04);
}

.checkbox-fix {
  display: flex;
  align-items: center;
}

.checkbox-fix label,
.checkbox-fix span {
  margin: 0px !important;
}

.header-button-group {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.import-modal {
  height: 132px;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.color-bg {
  display: inline-block;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 0.7px solid lightgrey;
}

.header-text {
  font-weight: bold;
}

.header-details {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media (max-width: 600px) {
  .header-button-group {
    justify-content: start;
  }
  .header-button-group .toolbar-btn,
  .header-button-group a {
    width: 50%;
  }

  .header-button-group a button {
    width: 100%;
  }

  .import-modal {
    width: 270px;
  }

  .card-body {
    padding: 10px;
  }

  .table td,
  .table th {
    padding: 7px;
  }
}
