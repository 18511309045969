a {
  text-decoration: none;
}
.Card_style {
  /* box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  color: #4A4A4A;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
  border-radius: 4px;
  padding: 45px;
  width: 100%; */
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  margin-bottom: 1rem;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 0.75rem 1.2rem;
}
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 0 solid rgba(0, 0, 0, 0.125);
}
.submit-cancel-box {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin: 0 auto;
}
.cat-sub-btn.MuiButton-contained {
  background-color: #2c60a4;
  margin: 0;
  width: fit-content;
  display: inline-block;
  color: #fff;
  font-size: 17px;
  line-height: 30px;
  font-weight: 600;
  text-transform: capitalize;
}
.cat-sub-btn.MuiButton-contained:hover {
  box-shadow: 0px 12px 33px 0px #e8eafc, 0 3px 3px -2px #b2b2b21a,
    0 1px 8px 0 #9a9a9a1a;
  background-color: #2c60a4;
}
.cat-subCancel-btn.MuiButton-contained {
  background-color: #dc3545;
  margin: 0;
  width: fit-content;
  display: block;
  color: #fff;
  font-size: 17px;
  line-height: 30px;
  font-weight: 600;
  text-transform: capitalize;
}
.cat-subCancel-btn.MuiButton-contained:hover {
  box-shadow: 0px 12px 33px 0px #e8eafc, 0 3px 3px -2px #b2b2b21a,
    0 1px 8px 0 #9a9a9a1a;
  background-color: #dc3545;
}

.action-icons {
  cursor: pointer;
}
.inputField {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.input-div {
  padding: 18.5px 14px;
  border: 1px solid #b9b9b9;
  border-radius: 4px;
  color: #6e6e6e;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}
.small-box-footer {
  display: block;
  padding: 3px 0;
  position: relative;
  text-align: left;
  text-decoration: none;
  color: #536dfe;
}

.table tbody tr:nth-of-type(odd) {
  /* background-color: rgba(0,0,0,.05) !important; */
}
